import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function TextDiplay2() {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-5xl md:px-24 lg:px-8 lg:py-20">
      <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto text-center">
          <span class="relative inline-block text-blue-900">Projet et Mission</span>
        </h2>
        <div class="flex mt-6 justify-center">
          <div class="w-16 h-1 rounded-full bg-blue-900 inline-flex"></div>
        </div>
      </div>
      <div className=" flex flex-col-reverse">
        <div className="">
          <div className="max-w-5xl mb-6 rounded-lg shadow-xl p-4">
            {/* <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Let us handle
              <br className="hidden md:block" />
              your next{' '}
              <span className="inline-block text-deep-purple-accent-400">destination</span>
            </h2> */}
            <p className="text-lg text-gray-900 md:text-xl mt-2">
              Notre mission est d’accompagner les élèves tout au long de leur parcours scolaire en
              leur inculquant les connaissances nécessaires à leur réussite, leur épanouissement et
              leur ouverture sur le monde.
            </p>
            <br />
            <p className="text-lg text-gray-900 md:text-xl">
              Pour cela, nous offrons une éducation d’excellence et rigoureuse sur les matières
              fondamentales tout en développant chez les élèves une dimension citoyenne, artistique,
              culturelle et linguistique, dans un cadre épanouissant.
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center -mx-4 lg:pl-8">
          <div className="flex flex-col items-end px-3">
            <StaticImage
              className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
              alt=""
              src="../images/etablissement-1.webp"
            />
            <StaticImage
              className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
              alt=""
              src="../images/cdi-1.webp"
            />
          </div>
          <div className="px-3">
            <StaticImage
              className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-auto sm:w-64 xl:w-80"
              alt=""
              src="../images/projet-1.webp"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
