import React from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import AnimationRevealPage from '../../components/AnimationRevealPage';

import TextDiplay2 from '../../components/TextDisplay2';

export default function NosValeurs() {
  return (
    <AnimationRevealPage>
      <Layout>
        <Seo
          title="Collège Jules Verne Kairouan - Nos projets"
          description="N'hésitez pas à nous contacter pour toutes questions ou demande"
        />
        <TextDiplay2 />
      </Layout>
    </AnimationRevealPage>
  );
}
